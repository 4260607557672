import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

class Audio extends React.Component {


  render() {

    return (
      <div className="foto-section">
     {this.props.audio.map((element, index) => {
        return <AudioPlayer key={index}
          src={process.env.PUBLIC_URL + '/assets/img/luoghi' + this.props.path + 'audio/' + element}          
        />
      })}
      </div>
    );
  }
}

export default Audio