import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Foto extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="foto-section">
      {this.props.gall.map((element,index)=>{
          return <img key={index} className="img-gallery" src={process.env.PUBLIC_URL  + '/assets/img/luoghi'+this.props.path+'gallery/' + element} onClick={() => this.setState({ isOpen: true,photoIndex: index })}></img>
})}

      {isOpen && (
        <Lightbox
          mainSrc={process.env.PUBLIC_URL + '/assets/img/luoghi/'+this.props.path+'gallery/'+this.props.gall[photoIndex]}
          nextSrc={process.env.PUBLIC_URL + '/assets/img/luoghi/'+this.props.path+'gallery/'+this.props.gall[(photoIndex + 1) % this.props.gall.length]}
          prevSrc={process.env.PUBLIC_URL + '/assets/img/luoghi/'+this.props.path+'gallery/'+this.props.gall[(photoIndex + this.props.gall.length - 1) % this.props.gall.length]}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + this.props.gall.length - 1) % this.props.gall.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % this.props.gall.length,
            })
          }
        />
      )}
    </div>

    );
  }
}

export default Foto