export const setIt = () => ({
  type: 'CHANGE_IT'
})

export const setEn = () => ({
    type: 'CHANGE_EN'
  })

export const addFilter = (filter) => ({
    type: 'ADD_FILTER',
    filter
  })
    