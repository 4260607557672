import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SliderDot from './components/SliderDot'
import Homepage from './components/Homepage';
import Mappa from './components/Mappa';
import Info from './components/Info';
import Ar from './components/Ar';
import Qr from './components/Qr';
import Arcont2 from './components/AR/Content2';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

class App extends React.Component {
  
  state = { width: 0, height: 0 };
   supportedLanguages = ["en", "it"];
   updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  detect(){
    
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];
  
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      });
  
  }
  tablet(){
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    console.log(isTablet)
    return isTablet;
  }

 render(){
  
  /*{if(( window.innerWidth >= 800 ) )
    {
    window.location.href='https://www.viaggiar.it/'
 
}}*/
  
  return (
    <div className="App">
     <BrowserRouter>
      <Switch>
      <Route exact path='/' component={Homepage} />
      <Route exact path='/:lang/' component={Homepage} />
      <Route exact path='/:lang/come-funziona' component={SliderDot} />
      <Route exact path='/:lang/mappa' component={Mappa} />
      <Route exact path='/:lang/ar-cont' component={Arcont2} />
      <Route exact path='/:lang/ar' component={Ar} />
      <Route exact path='/:lang/info' component={Info} />
      <Route exact path='/:lang/qr' component={Qr} />
              <Route path="/:lang/"
            
          render={props =>
            this.supportedLanguages.includes(props.match.params.lang) ? (
              <Homepage {...props}  />             
            ) : (
              <Redirect to={`/it/${props.match.params.lang}`} />
            )
           }
           
        />       
     </Switch>  
         </BrowserRouter>
     
  
    </div>
  );
          }
}

export default App;
