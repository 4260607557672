import React from 'react';
import {  Swipeable } from 'react-swipeable'
import luoghi from '../../data/luoghi.json'
import cat from '../../data/categorie.json'
import Content from './Content'
class Anteprima extends React.Component {
  
  state={
    "openCont":false,
    "curr":{}
    
  }

  constructor(props) {
    super(props);   
  }
  
  swipeUp(){        
    this.setState({"openCont":true})   
    this.props.open();
    luoghi.map((element)=>{
      if(element.id==this.props.luogoid){
        this.setState({"curr":element})
      }
    })    
  }
  closeCont(){
     this.setState({"openCont":false})
     this.props.close();
  }
  
  render() {
    
    return (
     this.state.openCont ?   <Content curr={this.state.curr} closeCont={()=>this.closeCont()}></Content> :
      luoghi.map((element, index) => { 
        if(this.props.luogoid==element.id){               
      return <Swipeable key={index} onSwipedUp={() => this.swipeUp()} >     
    <div id="anteprima">
          <div className="swipe">
            <p className="swipe-icon"></p>
          </div>
          <div id="content-anteprima" onClick={() => this.swipeUp()}>
            <div className="image-anteprima" >
              <img src={process.env.PUBLIC_URL  + '/assets/img/luoghi'+element.path+'cover/' + element.img} style={{ width: "140px" }}></img>
            </div>
            <div className="descr-anteprima"> <p>
              {cat.map((el,index) => {
                if (el.slug == element.categoria) {
                return  <span key={index} style={{ color: el.color }}><img className="icon-info" src={process.env.PUBLIC_URL + '/assets/img/categorie/'+el.path +'icona/' +el.img}></img><span className="info-mappa-cat">{el.nome}</span></span>}})}<span className="text-bold distance">{this.props.distance} m</span></p>
                    <p className="text-bold size-16">{element.nome}</p>
          </div>
            </div>
          </div>
          </Swipeable>
        }
      })
     
    );
  }
}

export default Anteprima