import React from 'react';
import Mappa from '../Mappa'
import Footer from '../Footer'
import Foto from './media/Foto'
import Audio from './media/Audio'
import Video from './media/Video'
import iconmap from '../../assets/icone/map.png'
import close from '../../assets/icone/close-red.png'
import cat from '../../data/categorie.json'
import { Tab, Tabs, } from 'react-bootstrap';

class Content extends React.Component {
 state={
   "more":false
 }
  closeHandle = () => {
    document.getElementById('popup-info').style.display = "none";
  }
  toDegreesMinutesAndSeconds(coordinate) {
		var absolute = Math.abs(coordinate);
		var degrees = Math.floor(absolute);
		var minutesNotTruncated = (absolute - degrees) * 60;
		var minutes = Math.floor(minutesNotTruncated);
		var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

		return degrees + " ° " + minutes + "' " + seconds + '"';
	}

	convertDMS(lat, lng) {
   
		var latitude = this.toDegreesMinutesAndSeconds(lat);
		var latitudeCardinal = lat >= 0 ? "N" : "S";
		var longitude = this.toDegreesMinutesAndSeconds(lng);
		var longitudeCardinal = lng >= 0 ? "E" : "W";
    return  <p className="descr text-regular"><strong>{latitudeCardinal}</strong>  {latitude} &nbsp; <strong>  {longitudeCardinal}  </strong>{longitude}</p>;
  
	}
  render() {
   
    return (

      <div class="ar-content">
        <div className="header-content">
          <img className="close-color" src={close} onClick={()=>this.props.closeCont()}></img>
          <img className="header-img" src={process.env.PUBLIC_URL  + '/assets/img/luoghi'+this.props.curr.path+'cover/' + this.props.curr.img}></img>
        </div>
        <div className="ar-content-info">
        {cat.map((el) => {
              if (el.slug ==  this.props.curr.categoria) {
                return <p className="category-cont" style={{ color: el.color }}><img className="icon-info" src={process.env.PUBLIC_URL + '/assets/img/categorie/'+ el.path+'icona/' + el.img}></img><span className="info-mappa-cat">{el.nome}</span></p>}
              })}
          <h1 className="text-bold">{this.props.curr.nome}</h1>
          <p className="text-regular descr">{this.state.more ? <span>{this.props.curr.descrizione.split('\n').map((item, key) => {
                  return <span key={key}>{item}<br /></span>
                })}< p className="read-more " onClick={() => this.setState({ "more": false })}>Chiudi</p></span> :<span>{this.props.curr.excerpt!=undefined ? this.props.curr.excerpt.split('\n').map((item, key) => {
                  return <span key={key}>{item}<br /></span>
                }):""}<p className="read-more " onClick={()=>this.setState({"more":true})}>Leggi tutto</p></span>}</p>
          <Tabs id="uncontrolled-tab-example">          
            {this.props.curr.gallery == "" || this.props.curr.gallery == undefined ? "" : <Tab eventKey="foto" title="FOTO">
              <Foto gall={this.props.curr.gallery} path={this.props.curr.path}></Foto>
            </Tab>}
            {this.props.curr.video == "" || this.props.curr.video == undefined ? "" : <Tab eventKey="video" title="VIDEO">
              <Video video={this.props.curr.video}></Video>
            </Tab>}
            {this.props.curr.audio == "" || this.props.curr.audio == undefined ? "" : <Tab eventKey="podcast" title="PODCAST" >
            <Audio audio={this.props.curr.audio}  path={this.props.curr.path}></Audio>
            </Tab>}
          </Tabs>
        </div>
        <hr></hr>
        <div className="ar-content-info mappa-content">
          <p className="size-18 text-bold">MAPPA<span className="map-link text-regular"><img src={iconmap} style={{ width: "21px" }}></img><a href="/it/mappa">Apri mappa</a></span></p>
          <Mappa current={this.props.curr.id}></Mappa>
          <br></br>
          <p className="descr text-regular">{this.props.curr.indirizzo}</p>
          {this.props.curr.pos!=undefined ? this.convertDMS(this.props.curr.pos.lat,this.props.curr.pos.lng) :""}
        </div>
        <Footer></Footer>
      </div>

    );

  }
}

export default Content