import categorie from '../data/categorie.json'
var initState={
    curr_lang:"it",
    
  
   filtri_attivi: categorie.map((element)=>{
     return {"nome":element.slug,"stato":true}
   })
}
const filter = (state = initState, action) => {
  switch (action.type) {
     case 'ADD_FILTER':  
     let temp= state.filtri_attivi.slice(0);     
     state.filtri_attivi.forEach((element,index)=>{      
      if(element.nome==action.filter){   
        if(element.stato){        
          temp[index].stato=false;        
        }else{
          temp[index].stato=true;
        }   
      }   
    })
   
    return Object.assign({}, state,{
      filtri_attivi:temp})     
    default:
      return state
  }
}

export default filter