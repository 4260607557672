import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import milano from '../assets/icone/milano.png'
import close from '../assets/icone/close.png'
import call from '../assets/icone/call-center.png'
class Info extends React.Component {
 
  render() {

    return (
        <div id="popup-info">
          <p className="text-right"><img className="close-btn" src={close} onClick={()=>this.props.close()}></img></p>
        <Row id="content-info">
          <Col md={12}>
            <img src={milano} style={{ width: "142px" }}></img>
          </Col>
        </Row>
       
        <Row className="circle-contact">
          <Col md={12}>
            <img src={call}  style={{ width: "32px" }}></img>
            <p className="text-regular size-16 no-margin"><br/>Contact Center </p>
            <p className="text-bold size-16" >02.02.02</p>
            <p className="text-regular descr">LUN-SAB (escluso festivi)<br/> dalle 8:00 alle 20:00</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p className="text-bold no-margin">
            Comune di Milano </p>
          <p className="text-regular descr">Piazza della Scala, 2<br/>
            20121 Milano<br/>
            Italia<br/>
            </p> 
            <a className="text-bold" href="#" style={{color:"#E20019"}}>comune.milano.it</a>
          </Col>
        </Row>

      </div>

    );
  }
}

export default Info