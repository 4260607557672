import React, { Component } from 'react';
import back from '../assets/icone/back-arrow.png'
import Filtro from '../components/Filtro'
import Pin from '../components/Pin'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
const containerStyle = {
  width: '100%',
  height: '100%'
};



 class  Mappa extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      info: "-1",
      currClick: {},
      center :{
        lat:0,
        lng:0    
      }     
    }
  }
 
   getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }
  }

  showPosition = (position) => {    
    this.setState({center:{lat:parseFloat(position.coords.latitude),lng:parseFloat(position.coords.longitude)}}) 
   
    
  }
  componentWillMount() {    
    this.getLocation();
  }
  
  setCurrClick(coord) {
    this.setState({ currClick: coord })
  }
  backHome = () => {
    window.location.href = '/homepage/'
  }

  
  render() {
   
   
    return (
     
      <div className="map-div">       
        <p className="text-left close-map" style={{ position: "absolute", zIndex: "1000" }}>
          <img src={back} style={{ width: "36px", cursor: "pointer", margin: "15px" }} onClick={() => this.backHome()}></img>
        </p>
        
        <LoadScript
          googleMapsApiKey="AIzaSyCK6QxdS3MoVolfonrt6ev_EGjGnCkes2E">
          <GoogleMap  
                 
            mapContainerStyle={containerStyle}
            center={this.state.center}
            zoom={18}
          >
            <Pin current={this.props.current}></Pin>
            <Marker
            icon={process.env.PUBLIC_URL  + '/assets/img/position.png'}
              position={this.state.center}
              title="Posizione corrente"
            ></Marker>
          
          </GoogleMap>
        </LoadScript>
        <Filtro></Filtro>
      </div>

    )
  }
}

export default Mappa;