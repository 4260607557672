import React from 'react';
import QrScanner from 'qr-scanner';
//import styles from '../assets/css/style.css'
class Qr extends React.Component {
   setResult(result) {
     console.log(result);
    if (result.substring(0, 4) == 'http') {
      //scanner.stop();
      document.location.href = result;
    }
    // scanner.stop();
  }
 componentDidMount(){
  QrScanner.WORKER_PATH =process.env.PUBLIC_URL+'/js/qr-scanner-worker.min.js';
  const video = document.getElementById('qr-video');
  QrScanner.hasCamera();
  const scanner = new QrScanner(video, result => this.setResult(result));
  scanner.start();
 }
  render() {

    return (
      <div class="video-wrapper">
			<video muted playsinline id="qr-video"></video>
		</div>	
    );
  }
}

export default Qr