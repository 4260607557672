import React from 'react';
import cover from '../assets/cover.png'
import question from '../assets/icone/question.png'
import map from '../assets/icone/map.png'
import ar from '../assets/icone/ar.png'
import frecciawhite from '../assets/icone/white.png'
import frecciablack from '../assets/icone/black.png'
import { Container, Row, Col} from 'react-bootstrap';
import milano from '../assets/icone/milano.png'
import viaggiar from '../assets/icone/logo-viaggiar.png'
import infologo from '../assets/icone/icon-info-outline.png'
import Info from '../components/Info'
import Footer from '../components/Footer'
class Homepage extends React.Component {
  state ={
    "openinfo":false
  }
  closeInfo=()=>{
    this.setState({"openinfo":false})
  }
  render() {
    return (
      this.state.openinfo ?  <Container className="homepage"><Row> <Info close={()=>this.closeInfo()}></Info> </Row></Container>    : <Container className="homepage">      
       
      <Row>
        <Col md={12} className="top-bar">
          
        <img src={milano} style={{width:"122px",float:"left"}}></img>     
        <img src={viaggiar} style={{width:"73px",float:"left",marginLeft:"15px",marginRight:"auto"}}></img>    
        
       <img src={infologo} style={{width:"20px",float:"right",cursor:"pointer"}} onClick={()=>this.setState({"openinfo":true})}></img>
        </Col>          
      </Row>
        <Row>   
        <Col md={12} className="no-padding">      
          <img src={cover} style={{maxWidth:"100%",height:"auto"}}></img>      
          </Col>    
      </Row>
      <Row>
        <Col md={12}>
          <p className="text-bold title">VIVI MILANO DALLA TUA PROSPETTIVA</p>
          <p className="text-regular descr">Vai alla scoperta della città di Milano attraverso l’esperienza straordinaria della Realtà Aumentata.</p>
        </Col>
      </Row>
      <a href="/it/ar">   <Row className="menu-btn-red">
        <Col xs={3}>
        <img src={ar} style={{width:"46px"}}></img>
        </Col><Col xs={6} className="text-left text-bold">
          <p>REALT&Agrave; <br /> AUMENTATA</p>
        </Col>
        <Col xs={3}>
       <img className="freccia" src={frecciawhite}></img>
        </Col>
      </Row>
      </a>
      <a href="/it/mappa"> <Row className="menu-btn-normal">
        <Col xs={3}>
        <img src={map} style={{width:"42px"}}></img>
        </Col><Col xs={6} className="text-left text-bold">
          <p>MAPPA</p>
        </Col>
        <Col xs={3}>
        <img  className="freccia" src={frecciablack}></img> 

        </Col>
      </Row></a>
      <a href="/it/come-funziona"> <Row className="menu-btn-normal" >
        <Col xs={3}>
        <img src={question} style={{width:"38px"}}></img>

        </Col><Col xs={6} className="text-left text-bold">
          <p>COME <br /> FUNZIONA?</p>

        </Col>
        <Col xs={3}>
      <img  className="freccia" src={frecciablack}></img>

        </Col>
      </Row>
      </a> 
      <br/>
      <Footer></Footer>

    </Container>   
      

    );
  }
}

export default Homepage