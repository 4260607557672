import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Marker, InfoWindow } from '@react-google-maps/api';
import luoghi from '../data/luoghi.json'
import cat from '../data/categorie.json'
class Pin extends Component {

  state = {
    info: "-1",
    filtri: this.props.filtri_attivi
  }


  componentWillReceiveProps(nextprops) {
    this.setState({ filtri: this.props.filtri_attivi });
  }
  showInfo = (element,index) => {
    //this.props.currClick(element.pos);
    this.setState({ info: index })
  }
  closeInfo=()=>{
    this.setState({info:"-1"})
  }

  getIconMap=(element)=>{
    var a="";
    cat.map((el) => {             
      if (el.slug == element.categoria) {
        if(element.id == this.props.current){
        a=  process.env.PUBLIC_URL  + '/assets/img/categorie/'+el.path+'mappa/'+el.mappabig
        }else{
        a=process.env.PUBLIC_URL  + '/assets/img/categorie/'+el.path+'mappa/'+el.mappa}}}
        )
        return a;
  }
  render() {
    
    return (

      luoghi.map((element, index) => {
        {
          return this.props.filtri_attivi.some(p => { if (p.nome == element.categoria && p.stato) { return true } }) ? <Marker key={index}
            position={element.pos}
           
           icon= {this.getIconMap(element)}
            title={element.nome}
            onClick={() => this.showInfo(element,index)}
          >
            {this.state.info == index ? <InfoWindow onCloseClick={()=>this.closeInfo()}><div className="info-mappa">{cat.map((el,index) => {
              if (el.slug == element.categoria) {
                return <span key={index} style={{ color: el.color }}><img className="icon-info" src={process.env.PUBLIC_URL + '/assets/img/categorie/' +el.path+'icona/'+ el.img}></img><span className="info-mappa-cat">{el.nome}</span></span>
              }
            })}<p>{element.nome}</p></div></InfoWindow> : ""}</Marker> : ""
        }
      })



    )
  }
}
const mapStateToProps = (state,ownProps) => ({
  ...state,ownProps
});
export default connect(mapStateToProps)(Pin);