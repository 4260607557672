import React from 'react';
import target from '../assets/icone/target2x.png'
import poi from '../data/luoghi.json'
import categ from '../data/categorie.json'
import Filtro from '../components/Filtro'
import back from '../assets/icone/back-arrow.png'
import Anteprima from './AR/Anteprima'
import Qr from './Qr'
import { connect } from 'react-redux'
class Ar extends React.Component {
	state = {
		"openAnteprima": false,
		"openid": "",
		"distance": "",
		"openTab": false,
		"filtri": this.props.filtri_attivi,
		"error": false
	}
	rapporto(distanza) {
		switch (true) {
			case (distanza < 2):
				return 0.2;
			case (distanza < 3):
				return 0.4;
			case (distanza < 5):
				return 0.8;
			case (distanza < 9):
				return 1;
			case (distanza < 15):
				return 2;
			case (distanza < 20):
				return 4;
			case (distanza < 30):
				return 5;
			case (distanza < 39):
				return 6;
			case (distanza < 50):
				return 8;
			case (distanza < 70):
				return 9;
			case (distanza < 80):
				return 13;
			case (distanza < 100):
				return 15;
			case (distanza < 120):
				return 19;
			case (distanza < 130):
				return 22;
			case (distanza < 150):
				return 25;

		}
	}
	filterPin() {
		var a = document.querySelectorAll('[gps-entity-place]')
		if (a.length > 0) {
			a.forEach(element => {
				if (this.props.filtri_attivi.some(p => { if (p.nome == element.getAttribute('cat') && p.stato) { return true } })) {
					element.object3D.visible = true;
				} else {
					element.object3D.visible = false;
				}
			})
		}
	}
	componentWillMount() {
		document.body.style.overflow = "hidden";

	}
	componentDidMount() {
		this.iniPin(this);
		window.addEventListener('gps-camera-update-position', () => { this.getPinDistance(this) });
	}

	backHome = () => {
		window.location.href = '/homepage/'
	}

	getPinDistance(comp) {

		let a = document.querySelectorAll('[gps-entity-place]');
		if (a.length > 0) {
			let dist = [];
			a.forEach(element => {		
				if(!element.is('cursor-hovered')){
					poi.map(element2 => {
						if (element.getAttribute('luogoid') == element2.id) {
							element.setAttribute('src', 'url(' + process.env.PUBLIC_URL + '/assets/img/luoghi/' + element2.path + 'pin/' + element2.pin + ')');
						}
					},element)
					comp.closeAnt();
					element.setAttribute('ov',false)
				}else{
					element.setAttribute('ov',true)
				}		
				if (Math.round(Number(element.getAttribute('distance')) > 0)) {
					let msgdist = Math.round(Number(element.getAttribute('distance'))) + " m";
					element.firstElementChild.setAttribute('value', msgdist);
				}
				dist.push(Math.round(Number(element.getAttribute('distance'))));
			});
			let allzero = dist.every(item => item === 0);
			if (!allzero) {
				dist = dist.sort();
				if (Math.min(...dist) > 150) {
					comp.displayErrore();
					comp.setState({ distance: Math.min(...dist) })
				}
				let cont = 0;
				let scala;
				var i = 0
				for (i = 0; i < a.length; i++) {
					scala = comp.rapporto(Math.round(Number(a[i].getAttribute('distance'))));
					a[i].object3D.scale.set(scala, scala, scala);
					if (Math.round(Number(a[i].getAttribute('distance'))) < 70) {
						cont++;
					}
				}
				var i = 0;
				var j = 0;
				a = Array.from(a);
				for (i = 0; i < a.length; i++) {
					for (j = 0; j < a.length - 1; j++) {
						if (Number(a[j].getAttribute('distance')) > Number(a[j + 1].getAttribute('distance'))) {
							let temp = a[j];
							a[j] = a[j + 1];
							a[j + 1] = temp;
						}
					}
				}
				if (cont > 3) {
					for (i = 0; i < a.length; i++) {
						if (i < 3) {
							a[i].object3D.visible = true;
						} else {
							a[i].object3D.visible = false;
						}
					}
				}
				else {
					for (i = 0; i < a.length; i++) {
						a[i].object3D.visible = true;
					}
				}
			}

		}
	}
	displayErrore() {
		this.setState({ "error": true })

	}
	openAnt(id, dist) {
		dist = Math.round(dist)
		this.setState({ "openAnteprima": true })
		this.setState({ "openid": id })
		this.setState({ "distance": dist })
	}
	closeAnt() {
		if (!this.state.openTab) {
			this.setState({ "openAnteprima": false });
		}
	}
	closeAnt2() {

		this.setState({ "openAnteprima": false });

	}

	iniPin(comp) {

		poi.map((element, index) => {
			//document.querySelector('a-scene').renderer.sortObjects = true;
			const scene = document.querySelector('a-scene'); // Select the scene created in the HTML
			return navigator.geolocation.getCurrentPosition(function (position) {
				// Call the geolocation API to get the user's coordinates			
				const latitude = element.pos.lat;
				const longitude = element.pos.lng;
				const text = document.createElement('a-text');
				text.object3D.position.set(0, -1, -4);
				text.setAttribute('align', 'center');
				const pin = document.createElement('a-image'); // Create a new <a-image> element
				pin.setAttribute('lat', latitude);
				pin.setAttribute('lng', longitude);
				//text.setAttribute('side','front');
				pin.setAttribute('side', 'front');
				text.setAttribute('look-at', '[gps-camera]');
				pin.setAttribute('gps-entity-place', `latitude: ${latitude}; longitude: ${longitude};`); // Add latitude and longitude to gps-entity place so AR.js knows where to place it in the physical space
				pin.setAttribute('class', 'custom-pin'); // Give it a class so the cursor knows to target it
				pin.setAttribute('look-at', '[gps-camera]'); // I'm not sure this does anything. It didn't do anything with flat images either.
				pin.setAttribute('emitevents', true); // Allows the pins themselves to emit events.
				pin.setAttribute('cursor', 'rayOrigin: mouse'); // Sets the origin of the RayCaster to the "mouse" cursor. Not sure this does anything.
				pin.setAttribute('raycaster', 'objects: [data-raycastable]'); // Again, not much sure about this. Docs seem quite vague.
				//pin.setAttribute('animation', 'property: object3D.position.y; to: 2.2; dir: alternate; dur: 2000; loop: true');
				pin.setAttribute('description', element.description);
				pin.setAttribute('glow', 'enabled:false');
				pin.setAttribute('id', 'pin-' + element.id);
				pin.object3D.position.set(0, element.altitude, 0);
				pin.setAttribute('luogoid', element.id);
				pin.setAttribute('width', 0.9);
				pin.setAttribute('height', 1);
				pin.setAttribute('cat', element.categoria);
				pin.object3D.scale.set(2, 2, 0);
				pin.setAttribute('src', 'url(' + process.env.PUBLIC_URL + '/assets/img/luoghi' + element.path + 'pin/' + element.pin + ')');
				pin.object3D.visible = false;
				pin.setAttribute('ov',false);
				pin.appendChild(text);
				scene.appendChild(pin); // Adds the pin to the markup.
				pin.addEventListener('loaded', (e) => { // Fires this event when the pin is loaded. Not sure it does much			

					window.dispatchEvent(new CustomEvent('gps-entity-place-loaded'))

				});

				pin.addEventListener('fusing', (e) => { // Listens to a "click" event on the pin
					let tgt = e.target;					
					if (tgt.getAttribute('visible')) {
						comp.openAnt(tgt.getAttribute('luogoid'), tgt.getAttribute('distance'));
						categ.map(element => {
							if (element.slug == tgt.getAttribute('cat')) {
								tgt.setAttribute('src', 'url(' + process.env.PUBLIC_URL + '/assets/img/categorie/' + element.path + 'pin/' + element.pinoff + ')')
							}
						})

					}

				});
				pin.addEventListener('mouseleave', (e) => {	
					if(!e.target.getAttribute('ov')){									
					comp.closeAnt()
					poi.map(element => {
						if (e.target.getAttribute('luogoid') == element.id) {
							e.target.setAttribute('src', 'url(' + process.env.PUBLIC_URL + '/assets/img/luoghi/' + element.path + 'pin/' + element.pin + ')');
						}
					})
					
	
				}
				



				});



			})
		})

	}



	render() {

		this.filterPin()

		return (

			<div className="ar-app" >
				<a-scene vr-mode-ui="enabled: false" embedded
					arjs="sourceType: webcam; sourceWidth:1280; sourceHeight:720; displayWidth: 1280; displayHeight: 720; debugUIEnabled: false;"
					fog="type: linear; color: #444" >
					<a-camera gps-camera="minDistance: 1;simulateAltitude:20;" rotation-reader>
						<a-image
							id="cursore"
							animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
							animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
							animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
							cursor="fuse: true;"
							position="0 0 -1"
							width="0.1"
							height="0.1"
							material="transparent:false;alphaTest: 0.5;"
							src={target}>

						</a-image>

					</a-camera>

				</a-scene>

				<div className="cont-ar">
					{this.state.openAnteprima ? <Anteprima close={() => { this.closeAnt2() }} open={() => { this.setState({ "openTab": true }) }} luogoid={this.state.openid} distance={this.state.distance}></Anteprima>
						: <Filtro></Filtro>}
					{this.state.error ? <p className="error-container">
						<img src={back} style={{ width: "36px", cursor: "pointer", position: "absolute", top: "15px", left: "15px" }} onClick={() => this.backHome()}></img>
						ATTENZIONE <br />La tua posizione è troppo lontana dai punti di interesse di  Milano.<br /><br /><strong>Distanza:  {Math.min(this.state.distance)}  mt</strong></p> : <p className="text-left">
							<img src={back} style={{ width: "36px", cursor: "pointer", margin: "15px" }} onClick={() => this.backHome()}></img>
						</p>}

				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	...state
});
export default connect(mapStateToProps)(Ar);