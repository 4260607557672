import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import azero from '../assets/icone/azero.png'
class Footer extends React.Component {
 
  render() {

    return (
        <div id="footer">
          <Row>
         <Col xs={6}><p className="copyright"><img src={azero}></img> &nbsp;©2020 Azero</p></Col>
        <Col xs={6}><p className="text-right"><a href="https://www.azero.it/privacy-policy/">Note legali</a>  |  <a href="https://www.azero.it/privacy-policy/">Privacy</a></p></Col>
         </Row>
      </div>

    );
  }
}

export default Footer